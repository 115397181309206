import { createRouter, createWebHistory } from 'vue-router'

const Layout = () => import( '../layout/index.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import( '../views/home/index.vue'),
        meta: {
          title: "首页",
          icon: "homepage",
          showSettings: true,
          hidden: true,
          id: '1'
        }
      },{
        path: 'about',
        component: () => import( '../views/about/index.vue'),
        meta: {
          title: "关于我们",
          showSettings: false,
          hidden: true,
          id: "5",
        }
      },{
        path: 'product',
        name: 'Product',
        component: () => import( '../views/product/index.vue'),
        meta: {
          title: "产品中心",
          showSettings: false,
          hidden: true,
          id: '2'
        }
      },{
        path: 'case',
        name: 'Case',
        component: () => import( '../views/case/all.vue'),
        meta: {
          title: "解决方案",
          showSettings: false,
          hidden: true,
          id: '3',
        },
      },{
        path: 'case/:type',
        name: 'CaseByType',
        component: () => import( '../views/case/index.vue'),
        meta: {
          title: "解决方案",
          showSettings: false,
          hidden: true,
          id: '3',
        },
        props: true,
      },{
        path: 'useCase',
        name: 'UseCase',
        component: () => import( '../views/useCase/all.vue'),
        meta: {
          title: "应用案例",
          showSettings: false,
          hidden: true,
          id: '4',
        },
      },{
        path: 'useCase/:type',
        name: 'UseCaseByType',
        component: () => import( '../views/useCase/index.vue'),
        meta: {
          title: "应用案例",
          showSettings: false,
          hidden: true,
          id: '4',
        },
        props: true,
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
